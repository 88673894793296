import { Injectable, inject } from '@angular/core'
import { AlertService } from '@core/services/alert.service'
import { SimpleStore } from '@core/states/simple-store'
import { tuiCeil } from '@taiga-ui/cdk'
import { shake } from 'radash'
import { combineLatest, debounceTime, switchMap, tap } from 'rxjs'
import { ReleaseNote } from '../models/release-note.model'
import { ReleaseNoteApiService } from '../services/release-note-api.service'

const PAGE_SIZE = 24

export interface ReleaseNoteListState {
    releaseNote: ReleaseNote[]
    loading: boolean
    totalPages: number
    totalResults: number
    searchTerm: string
    page: number
    pageSize: number
}

const initialReleaseNoteListState: ReleaseNoteListState = {
    releaseNote: [],
    loading: false,
    totalPages: 1,
    totalResults: 0,
    searchTerm: '',
    page: 1,
    pageSize: PAGE_SIZE,
}

@Injectable({
    providedIn: 'root',
})
export class ReleaseNoteListStateService extends SimpleStore<ReleaseNoteListState> {
    private releaseNoteApiService = inject(ReleaseNoteApiService)
    private alertService = inject(AlertService)

    constructor() {
        super(initialReleaseNoteListState)
        this.init()
    }

    init() {
        this.continueLoadingReleaseNote()
        this.continueResetingStateOnSearchTermChange()
    }

    private continueLoadingReleaseNote() {
        combineLatest([this.select('page'), this.select('pageSize'), this.select('searchTerm')])
            .pipe(
                debounceTime(300),
                tap(() => this.setState({ loading: true })),
                switchMap(([currentPage, pageSize, search]) => {
                    return this.releaseNoteApiService.find(
                        shake({
                            page: currentPage,
                            search,
                            size: pageSize,
                            sortBy: 'createdAt',
                            orderBy: 'desc',
                        }),
                    )
                }),
            )
            .subscribe({
                next: (data) => {
                    this.setState({
                        releaseNote: data.data,
                        loading: false,
                        totalResults: data.meta.total,
                        totalPages: tuiCeil(data.meta.total / PAGE_SIZE),
                    })
                },
                error: (error) => {
                    this.alertService.error(error.message)
                    this.setState({ loading: false })
                },
            })
    }

    // when search term or filters change, reset pagination in order to fetch new contents
    private continueResetingStateOnSearchTermChange() {
        combineLatest([this.select('searchTerm')]).subscribe({
            next: () => {
                this.setState({
                    page: 1,
                    pageSize: PAGE_SIZE,
                    totalPages: 1,
                    totalResults: 0,
                    releaseNote: [],
                })
            },
        })
    }
}
